import React from 'react'
import Maintenance from './Maintenance'

function Maintenances(props) {
    return (
        <div className="overflow-x-auto">
            <table className="rounded bg-gray-200 w-full text-center divide-y divide-black">
                <thead className="">
                    <tr>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Description</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Amount</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">From</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">End</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300">
                    {props.maintenances.map((maintenance) => (
                        <Maintenance key={maintenance.id} maintenance={maintenance} />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="">
                        <td colSpan="6" className="py-2 font-medium text-sm text-right pr-4">Showing {props.maintenances.length} maintenances in total</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default Maintenances
