import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function ProjectItem(props) {
    const token = useSelector(state => state.token)
    const [cost, setCost] = useState(0)
    const [hours, setHours] = useState(0)
    const [rate, setRate] = useState(0)
    const [completed, setCompleted] = useState(false)

    useEffect(() => {
        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${props.project.id}/tasks/`,{
            headers:{
                "Authorization": "Token " + token 
            }
        })
        .then(res => res.json())
        .then((data) => {
            let cost = 0, hours = 0
            data.forEach(task => {
                cost += task.cost
                hours += task.hours
            });
            setCost(cost)
            setHours(hours)
            setRate((cost / hours) || 0)

            if (props.project.endDate && new Date(props.project.endDate) <= new Date()){
                setCompleted(true)
            }
        })
    }, [])

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const formatDate = ((date)=> {
        if (!date){
            return ''
        }
        const d = new Date(date)
        const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
        const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)
        const year = d.getFullYear()

        return day + "/" + month + "/" + year
    })

    return (
        <Link to={`/projects/${props.project.id}/`}>
            <div className=
                {classNames(completed? "border-green-500" : "border-red-500",
                    "flex justify-between bg-gray-300 p-4 rounded-lg shadow-lg border-l-8")
                }>
                <div className="flex flex-col justify-center">
                    <h1 className="text-3xl">{props.project.name}</h1>
                    <p className="font-bold">Start: <span className="font-normal">{formatDate(props.project.startDate)}</span></p>
                    <p className="font-bold">End: <span className="font-normal">{formatDate(props.project.endDate)}</span></p>
                </div>
                <div className="flex">
                    <div className="flex flex-col justify-center">
                        <p className="font-bold">Cost: </p>
                        <p className="font-bold">Hours: </p>
                        <p className="font-bold">Rate: </p>    
                    </div>
                    <div className="flex flex-col ml-2 text-right justify-center">
                        <p className="font-bold text-green-500">€ {cost}</p>
                        <p className="font-bold text-blue-500">{hours}</p>
                        <p className="font-bold text-yellow-500">€ {rate.toFixed(2)}</p>
                    </div>
                    
                </div>
            </div>
        </Link>
    )
}

export default ProjectItem
