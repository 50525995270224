import React, { useState } from 'react'
import { LoginIcon } from '@heroicons/react/outline'
import { useDispatch } from 'react-redux'
import {login} from './../actions/index'
import { useHistory } from 'react-router-dom'

function Login() {
    const history = useHistory()
    const dispatch = useDispatch()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [visible, setVisible] = useState(false)

    const handleDismiss = () => {
        setVisible(false)
    }

    const handleLogin = (e) => {
        e.preventDefault()
        const data = {
            username,
            password
        }
        fetch("https://admin.lamtechsoftwareservices.com/api/login/", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then((data) => {
            if (data.token) {
                localStorage.setItem("token", data.token)
                dispatch(login(data.token))
                history.push("/")
            }
            else{
                setVisible(true)
            }
        })
    }

    return (
        <div className="mt-52 max-w-lg mx-auto">
            { visible && 
                <div className="mx-auto w-full max-w-xl transition ease-in delay-1000 mt-4 bg-red-100 border border-red-500 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">Invalid credentials!</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg onClick={() => handleDismiss()}className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                    </span>
                </div>
            }
            
            <form className="w-full mx-auto shadow gap-2 p-4 bg-gray-200 rounded-lg mt-4" onSubmit={handleLogin}>
                <h1 className="text-3xl mb-4">Login</h1>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Username</label>
                        <input id="name" className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Username" type="text" name="username" value={username} required onChange={(e) => setUsername(e.target.value)}></input>
                    </div>

                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Password</label>
                        <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Password" type="password" name="password" value={password} required onChange={(e) => setPassword(e.target.value)} autoComplete="on"></input>
                    </div>

                    <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center">
                        <LoginIcon className="w-5 h-5 mr-2" />
                        <span>Login</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Login
