import React from 'react'
import Client from './Client'

function Clients(props) {

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {props.clients.map((client) => (
                <Client key={client.id} client={client} />
            ))}
        </div>
    )
}

export default Clients
