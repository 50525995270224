import { combineReducers } from "redux"
import ClientsReducer from "./ClientsReducer";
import MaintenancesReducer from "./MaintenancesReducer";
import PaymentsReducer from "./PaymentsReducer";
import TasksReducer from "./TasksReducer";
import TokenReducer from "./TokenReducer";

const reducers = combineReducers({
    token: TokenReducer,
    clients: ClientsReducer,
    tasks: TasksReducer,
    payments: PaymentsReducer,
    maintenances: MaintenancesReducer
})

export default reducers;