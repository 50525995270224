import React, { useEffect, useState } from 'react'

function Summary(props) {
    const [total, setTotal] = useState(0)
    const [totalTasks, setTotalTasks] = useState(0)
    const [totalPayments, setTotalPayments] = useState(0)
    const [totalMaintenances, setTotalMaintenances] = useState(0)

    useEffect(() => {
        let total = 0
        props.tasks.forEach(element => {
            total += element.cost
        });
        
        setTotalTasks(total)
    }, [props.tasks])

    useEffect(() => {
        let total = 0
        props.payments.forEach(element => {
            total += element.amount
        });
        setTotalPayments(total)
    }, [props.payments])

    useEffect(() => {
        let total = 0
        props.maintenances.forEach(element => {
            total += element.amount
        });
        setTotalMaintenances(total)
    }, [props.maintenances])

    useEffect(() => {
        setTotal(totalPayments - totalTasks - totalMaintenances)
    }, [totalPayments, totalTasks, totalMaintenances])

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className="overflow-x-auto">
            <table className="rounded bg-gray-200 w-full text-center divide-y divide-black">
                <thead className="">
                    <tr>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Aggregate</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Amount</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300">
                    <tr className="bg-gray-50">
                        <td className="p-2">Tasks</td>
                        <td className="p-2">{totalTasks}</td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="p-2">Maintenances</td>
                        <td className="p-2">{totalMaintenances}</td>
                    </tr>
                    <tr className="bg-gray-50">
                        <td className="p-2">Payments</td>
                        <td className="p-2">{totalPayments}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr className="">
                        <td className="py-2 uppercase font-medium text">Total</td>
                        <td className={classNames(total >= 0 ? "text-green-500" : "text-red-500", "py-2 uppercase font-medium text")}>{total}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default Summary
