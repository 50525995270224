export const login = (token) => {
    return {
        type: 'LOGIN',
        payload: token
    }
}

export const logout = () => {
    return {
        type: 'LOGOUT',
    }
}

export const fetchClients = (clients) => {
    return {
        type: 'FETCH_CLIENTS',
        payload: clients
    }
}

export const addClient = (client) => {
    return {
        type: 'ADD_CLIENT',
        payload: client
    }
}

export const deleteClient = (client) => {
    return {
        type: 'DELETE_CLIENT',
        payload: client
    }
}

export const fetchTasks = (tasks) => {
    return {
        type: 'FETCH_TASKS',
        payload: tasks
    }
}

export const addTask = (task) => {
    return {
        type: 'ADD_TASK',
        payload: task
    }
}

export const deleteTask = (task) => {
    return {
        type: 'DELETE_TASK',
        payload: task
    }
}

export const toggleTask = (task) => {
    return {
        type: 'TOGGLE_TASK',
        payload: task
    }
}

export const fetchPayments = (payments) => {
    return {
        type: 'FETCH_PAYMENTS',
        payload: payments
    }
}

export const addPayment = (payment) => {
    return {
        type: 'ADD_PAYMENT',
        payload: payment
    }
}

export const deletePayment = (payment) => {
    return {
        type: 'DELETE_PAYMENT',
        payload: payment
    }
}

export const fetchMaintenances = (maintenances) => {
    return {
        type: 'FETCH_MAINTENANCES',
        payload: maintenances
    }
}

export const addMaintenance = (maintenance) => {
    return {
        type: 'ADD_MAINTENANCE',
        payload: maintenance
    }
}

export const deleteMaintenance = (maintenance) => {
    return {
        type: 'DELETE_MAINTENANCE',
        payload: maintenance
    }
}