import { XIcon } from '@heroicons/react/outline';
import { MenuIcon, UserCircleIcon } from '@heroicons/react/solid';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { logout } from '../actions';

function Header() {
    const history = useHistory()
    const token = useSelector(state => state.token)
    const dispatch = useDispatch()
    const location = useLocation().pathname
    const [active, setActive] = useState(location)

    const [open, setOpen] = useState(false)

    const toggleMenu = () => {
        setOpen(!open)
    }

    useEffect(() => {
        setActive(location)
    }, [location])

    const handleClick = (to) => {
        setOpen(false)
        if (!token){
            history.push("/login")
        }
        else {
            history.push(to)
        }
    }
    
    const links = [
        {
            to: "/",
            text: "Home",
        },
        {
            to: "/projects",
            text: "Projects",
        }
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const handleLogout = () => {
        setOpen(false)
        dispatch(logout())
        history.push("/login")
        localStorage.removeItem("token")
    }

    return (
        <header className="bg-gray-800 text-white text-center h-16">
            <div className="flex sm:hidden h-full items-center px-4 justify-between">
                <div>
                    <button onClick={() => handleClick("/")}>
                        <h1 className="text-3xl">WeekLean</h1>
                    </button>
                </div>
                <div>
                    { open
                        ?
                            <XIcon className="h-10 w-10 animate-bounce" onClick={() => toggleMenu()} />
                        :
                            <MenuIcon className="h-10 w-10" onClick={() => toggleMenu()} />
                    } 
                </div>
            </div>

            { open &&
            <div className="absolute top-16 left-0 flex flex-col sm:hidden w-full items-end px-4 text-black bg-gray-600 gap-4 py-4">
                {links.map(link => (
                    <button 
                        key={link.to} 
                        onClick={() => handleClick(link.to)}
                        className={classNames(
                            link.to === active? "text-white font-bold": "text-gray-300",
                            "py-2 px-2 rounded-md"
                        )}
                    >
                        {link.text}
                    </button>
                ))}
                { token 
                    ?
                        <button onClick={() => handleLogout()} className="py-2 px-2 rounded-md flex justify-center items-center hover:bg-gray-700 text-gray-300">
                            <UserCircleIcon className="w-5 h-5 mr-1" />
                            <span>Logout</span>
                        </button>
                    :
                    <button 
                        key="/login" 
                        onClick={() => handleClick("/login")}
                        className={classNames(
                            "/login" === active? "text-white": "text-gray-300",
                            "py-2 px-2 rounded-md flex justify-center items-center"
                        )}
                    >
                        <UserCircleIcon className="w-5 h-5 mr-1" />
                        <span>Login</span>
                    </button> 
                }
            </div>
            }

            <div className="hidden sm:flex h-full items-center px-4 justify-between">
                <div>
                    <Link to="/">
                        <h1 className="text-3xl">WeekLean</h1>
                    </Link>
                </div>
                <div className="flex align-middle space-x-4">
                    {links.map(link => (
                        <button 
                            key={link.to} 
                            onClick={() => handleClick(link.to)}
                            className={classNames(
                                link.to === active? "bg-gray-900 text-white": "hover:bg-gray-700 text-gray-300",
                                "py-2 px-2 rounded-md"
                            )}
                        >
                            {link.text}
                        </button>
                    ))}
                </div>
                <div>
                    <div className="flex align-middle space-x-4">
                        { token 
                            ?
                                <button onClick={() => handleLogout()} className="py-2 px-2 rounded-md flex justify-center items-center hover:bg-gray-700 text-gray-300">
                                    <UserCircleIcon className="w-5 h-5 mr-1" />
                                    <span>Logout</span>
                                </button>
                            :
                            <Link 
                                key="/login" 
                                to="/login" 
                                className={classNames(
                                    "/login" === active? "bg-gray-900 text-white": "hover:bg-gray-700 text-gray-300",
                                    "py-2 px-2 rounded-md flex justify-center items-center"
                                )}
                            >
                                <UserCircleIcon className="w-5 h-5 mr-1" />
                                <span>Login</span>
                            </Link> 
                        }
                        
                    </div>
                
                </div>
            </div>
        </header>
    )
}

export default Header
