const ClientsReducer = (state=[], action) => {
    switch(action.type){
        case "FETCH_CLIENTS":
            return action.payload
        case "ADD_CLIENT":
            return [
                ...state,
                action.payload
            ]
        case "DELETE_CLIENT":
            return state.filter(client => client.id !== action.payload.id)
        default:
            return state
    }
}

export default ClientsReducer;