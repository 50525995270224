import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import EditClient from './components/EditClient';
import AddClient from './components/AddClient';
import ProjectsList from './components/ProjectsList';
import AddProject from './components/AddProject';
import Project from './components/Project';
import Login from './components/Login';
import AddTask from './components/AddTask';
import AddPayment from './components/AddPayment';
import EditTask from './components/EditTask';
import EditPayment from './components/EditPayment';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './actions';
import { useEffect } from 'react';
import EditProject from './components/EditProject';
import AddMaintenance from './components/AddMaintenance';
import EditMaintenance from './components/EditMaintenance';

function App() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.token)

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      dispatch(login(token))
    }
  }, [])

  return (
    <Router>
      <Header />
      <main className="container-fluid p-4 mb-16">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/clients/:id">
            <EditClient />
          </Route>
          <Route exact path="/tasks/:id">
            <EditTask />
          </Route>
          <Route exact path="/payments/:id">
            <EditPayment />
          </Route>
          <Route exact path="/maintenances/:id">
            <EditMaintenance />
          </Route>
          <Route exact path="/projects">
            <ProjectsList />
          </Route>
          <Route exact path="/projects/add">
            <AddProject />
          </Route>
          <Route exact path="/projects/:id">
            <Project />
          </Route>
          <Route exact path="/projects/:id/edit">
            <EditProject />
          </Route>
          <Route exact path="/projects/:id/clients">
            <AddClient />
          </Route>
          <Route exact path="/projects/:id/tasks">
            <AddTask />
          </Route>
          <Route exact path="/projects/:id/payments">
            <AddPayment />
          </Route>
          <Route exact path="/projects/:id/maintenances">
            <AddMaintenance />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
        </Switch>
        { token
          ?
            <Redirect to="/" />
          :
            <Redirect to="/login" />
        }
      </main>
      <Footer />
    </Router>
  );
}

export default App;
