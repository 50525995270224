import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/outline';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addTask } from '../actions';

function AddTask() {
    const token = useSelector(state => state.token)
    const history = useHistory();
    const id = parseInt(useParams().id)
    const dispatch = useDispatch()

    const [visible, setVisible] = useState(false)
    const [name, setName] = useState("")
    const [hours, setHours] = useState("")
    const [cost, setCost] = useState("")

    const handleDismiss = () => {
        setVisible(false)
    }

    const handleAdd = (e) => {
        e.preventDefault()

        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/tasks/`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Token " + token 
            },
            body: JSON.stringify({
                name,
                hours,
                cost,
                completed: false,
                project: id
            })
        })
        .then(res => res.json())
        .then((data) => {
            dispatch(addTask(data))
            setVisible(true)
            setName("")
            setHours("")
            setCost("")
        })
    }
    
    return (
        <div>
            <button className="text-white bg-gray-800 hover:bg-gray-900 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.goBack()}>
                <ArrowLeftIcon className=" w-5 h-5 mr-2" />
                <span>Back</span>
            </button>

            { visible && 
                <div className="mx-auto w-full max-w-xl transition ease-in delay-1000 mt-4 bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">Task added!</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg onClick={() => handleDismiss()}className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                    </span>
                </div>
            }

            <form className="w-full max-w-xl mx-auto shadow gap-2 p-4 mt-4 bg-gray-200 rounded-lg" onSubmit={handleAdd}>
                <h1 className="text-3xl mb-4">Add Task</h1>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Name</label>
                        <input id="name" className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Name" type="text" name="name" value={name} required onChange={(e) => setName(e.target.value)}></input>
                    </div>

                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Hours</label>
                        <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Hours" type="number" name="hours" value={hours} required onChange={(e) => setHours(e.target.value)}></input>
                    </div>
                    
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Cost</label>
                        <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Cost" type="number" name="cost" value={cost} required onChange={(e) => setCost(e.target.value)}></input>
                    </div>
                    
                    <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center">
                        <PlusIcon className="w-5 h-5 mr-2" />
                        <span>Add</span>
                    </button>
                </div>
            </form>
            
        </div>
    )
}

export default AddTask
