import React from 'react'
import Payment from './Payment'

function Payments(props) {
    return (
        <div className="overflow-x-auto">
            <table className="rounded bg-gray-200 w-full text-center divide-y divide-black">
                <thead className="">
                    <tr>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Description</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Date</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Amount</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Action</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300">
                    {props.payments.map((payment) => (
                        <Payment key={payment.id} payment={payment} />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="">
                        <td colSpan="6" className="py-2 font-medium text-sm text-right pr-4">Showing {props.payments.length} payments in total</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default Payments
