const TokenReducer = (state=null, action) => {
    switch(action.type){
        case "LOGIN":
            return action.payload
        case "LOGOUT":
            return null
        default:
            return state
    }
}

export default TokenReducer;