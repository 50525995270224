import React from 'react'

function Footer() {
    return (
        <footer className="bg-gray-800 text-center text-white fixed bottom-0 left-0 w-full h-16 px-4 flex flex-1 items-center justify-between">
            <div className="text-lg font-bold">
                WeekLean CRM
            </div>
            <div>
                Developed by: 
                <a className="ml-2 hover:underline hover:text-green-700" href="https://lamtechsoftwareservices.com/">Lamtech Software Services</a>
            </div>
        </footer>
    )
}

export default Footer
