import React from 'react'
import { Link } from 'react-router-dom'
import { UserIcon, AtSymbolIcon, PhoneIcon, XCircleIcon } from "@heroicons/react/outline"
import blank_user from './../assets/img/blank_user.png'
import { deleteClient } from '../actions'
import { useDispatch, useSelector } from 'react-redux'

function ProjectClient(props) {
    const token = useSelector(state => state.token)

    const dispatch = useDispatch()

    const handleDelete = (client) => {
        if (window.confirm(`Are you sure you want to delete ${client.name} ${client.surname} from clients?`)) {
            fetch(`https://admin.lamtechsoftwareservices.com/api/clients/${client.id}/`, {
                method: "DELETE",
                headers:{
                    "Authorization": "Token " + token 
                }
            })
            .then(dispatch(deleteClient(client)))
        }
    }

    return (
        <div key={props.client.id} className="bg-gray-300 rounded-lg shadow-lg flex items-center">
            <Link to={`/clients/${props.client.id}`}>
            <div className="h-32 w-32">
                {props.client.photo
                    ? <img className="rounded-l-lg h-full w-full object-cover" src={props.client.photo} alt=""></img>
                    : <img className="rounded-l-lg h-full w-full object-cover" src={blank_user} alt=""></img>     
                }
            </div>
            </Link>
            <div className="flex flex-col gap-1 mt-1 w-full">
                <div className="flex gap-1 items-center ml-2">
                    <div>
                        <UserIcon className="h-5 w-5" />
                    </div>
                    <div>
                        <p>{props.client.name} {props.client.surname}</p>
                    </div>
                </div>
                <div className="flex gap-1 items-center ml-2">
                    <div>
                        <AtSymbolIcon className="h-5 w-5" />
                    </div>
                    <div>
                        <p>{props.client.email || "N/A"}</p>
                    </div>
                </div>
                <div className="flex gap-1 items-center ml-2">
                    <div>
                        <PhoneIcon className="h-5 w-5" />
                    </div>
                    <div>
                        <p>{props.client.phone || "N/A"}</p>
                    </div>
                </div>  
              
                <button className="text-white bg-red-500 hover:bg-red-600 font-bold py-2 px-4 rounded-br inline-flex items-center justify-center" onClick={() => handleDelete(props.client)}>
                    <XCircleIcon className=" w-5 h-5 mr-2" />
                    <span>Delete</span>
                </button>
            </div>
        </div>
    )
}

export default ProjectClient
