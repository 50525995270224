import React from 'react'
import { useState } from 'react'
import { CameraIcon, PlusCircleIcon, ArrowLeftIcon } from '@heroicons/react/outline'
import { addClient } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

function AddClient() {
    const token = useSelector(state => state.token)
    const history = useHistory()
    const id = parseInt(useParams().id)

    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [photo, setPhoto] = useState([null, ""])
    
    const handleAdd = (e) => {
        e.preventDefault()
        
        const formData = new FormData()
        formData.append("name", name)
        formData.append("surname", surname)
        formData.append("email", email)
        formData.append("phone", phone)
        formData.append("project", id)
        //formData.append("csrfmiddlewaretoken", Cookies.get("csrftoken"))

        if (photo[0]) {
            formData.append("photo", photo[0], photo[1])
        }

        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/clients/`, {
            method: "POST",
            headers: {
                "Authorization": "Token " + token 
            },
            body: formData
        })
        .then(res => res.json())
        .then((data) => {
            dispatch(addClient(data))
            setName("")
            setSurname("")
            setEmail("")
            setPhone("")
            setPhoto([null, ""])
            setVisible(true)
        })
    }

    const handleDismiss = () => {
        setVisible(false)
    }
    
    return (
        <div>
            <button className="text-white bg-gray-800 hover:bg-gray-900 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.goBack()}>
                <ArrowLeftIcon className=" w-5 h-5 mr-2" />
                <span>Back</span>
            </button>

            { visible && 
                <div className="mx-auto max-w-xl transition ease-in delay-1000 mt-4 bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">Client added!</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg onClick={() => handleDismiss()}className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                    </span>
                </div>
            }

            <form className="w-full max-w-xl mx-auto shadow gap-2 p-4 mt-4 bg-gray-200 rounded-lg" onSubmit={handleAdd}>
                <h1 className="text-3xl mb-4">Add Client</h1>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Name</label>
                        <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Name" type="text" name="name" value={name} required onChange={(e) => setName(e.target.value)}></input>
                    </div>
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Surname</label>
                        <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Surname" type="text" name="surname" value={surname} required onChange={(e) => setSurname(e.target.value)}></input>
                    </div>
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Email</label>
                        <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Email" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                    </div>
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Phone</label>
                        <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Phone" maxLength="8" type="text" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}></input>
                    </div>
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Photo</label>
                        <div className="flex gap-4 items-center">
                            <CameraIcon className="w-10 text-blue-500" />
                            <input className="w-full shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" type="file" name="photo" value={photo[1]} onChange={(e) => setPhoto(e.target.files)}></input>
                        </div>
                    </div>
                    <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center">
                        <PlusCircleIcon className="w-5 h-5 mr-2" />
                        <span>Add</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddClient
