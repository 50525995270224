const TasksReducer = (state=[], action) => {
    switch(action.type){
        case "FETCH_TASKS":
            return action.payload
        case "ADD_TASK":
            return [
                ...state,
                action.payload
            ]
        case "DELETE_TASK":
            return state.filter(task => task.id !== action.payload.id)
        case "TOGGLE_TASK":
            return state.map((task) => {
                if (task.id === action.payload.id) {
                    task.completed = !task.completed
                }
                return task
            })
        default:
            return state
    }
}

export default TasksReducer;