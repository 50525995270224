import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Bar, Line } from 'react-chartjs-2'

function Home() {
    const token = useSelector(state => state.token)
    const [yearly, setYearly] = useState({})
    const [monthly, setMonthly] = useState({})
    const [daily, setDaily] = useState({})

    useEffect(() => {
        fetch("https://admin.lamtechsoftwareservices.com/api/payments/", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + token 
            }
        })
        .then(res => res.json())
        .then((res) => {
            let data = {}
            Array.from(res).forEach((item) => {
                const year = item.date.split("-")[0]
                data[year] = (data[year] || 0) + item.amount
            });
            setYearly(data)

            data = {}
            Array.from(res).forEach((item) => {
                const month = item.date.split("-")[1] + "/" + item.date.split("-")[0]
                data[month] = (data[month] || 0) + item.amount
            });

            // const start = res[0].date.split("-")[1] + "/" + res[0].date.split("-")[0]
            // const end = ((new Date().getMonth() + 1) < 10 ? "0" : "") + (new Date().getMonth() + 1) + "/" + new Date().getFullYear()
            // let i = start
            // let monthly = [[], []]
            // while (i != end) {
            //     monthly[0].push(i)
            //     monthly[1].push(data[i] || 0)
            //     i = incrementDateByMonth(i)
            // }
            // monthly[0].push(end)
            // monthly[1].push(data[end] || 0)
            
            setMonthly(data)

            data = {}
            let total = 0
            Array.from(res).forEach((item) => {
                
                const year = item.date.split("-")[2] + "/" + item.date.split("-")[1] + "/" + item.date.split("-")[0]
                data[year] = (data[year] || total) + item.amount
                total += item.amount
            });
            setDaily(data)
        })
    }, [])

    // const incrementDateByMonth = (date) => {
    //     const month = parseInt(date.split("/")[0])
    //     const year = parseInt(date.split("/")[1])

    //     if (month == 12) {
    //         return "01" + "/" + (year + 1)
    //     }
    //     else {
    //         return (month < 9 ? "0" : "") + (month + 1) + "/" + year
    //     }
    // } 


    return (
        <div className="flex flex-col">
            <div className="">
                <Line
                    data={{
                        labels: Object.keys(daily),
                        datasets: [{
                            label: "Overall Income",
                            borderWidth: 1,
                            backgroundColor: 'rgba(102,178,255,0.4)',
                            borderColor: 'rgba(0,0,0,1)',
                            data: Object.values(daily)
                        }]
                    }}
                    options={{
                        fill: true
                    }}
                />
            </div>
            <div className="grid sm:grid-cols-2">
                <div className="">
                <Bar
                        data={{
                            labels: Object.keys(monthly),
                            datasets: [{
                                label: "Monthly Payments",
                                borderWidth: 1,
                                backgroundColor: 'rgba(102,255,102,0.4)',
                                borderColor: 'rgba(0,0,0,1)',
                                data: Object.values(monthly),
                            }]
                        }}
                        options={{
                            
                        }}
                    />
                </div>
                <div>
                    <Bar
                        data={{
                            labels: Object.keys(yearly),
                            datasets: [{
                                label: "Yearly Payments",
                                borderWidth: 1,
                                backgroundColor: 'rgba(255,178,102,0.4)',
                                borderColor: 'rgba(0,0,0,1)',
                                data: Object.values(yearly)
                            }]
                        }}
                        options={{
                          
                        }}
                    />
                </div>
            </div>
      </div>
    )
}

export default Home
