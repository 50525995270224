import React from 'react'
import Task from './Task'

function Tasks(props) {
    return (
        <div className="overflow-x-auto">
            <table className="rounded bg-gray-200 w-full text-center divide-y divide-black">
                <thead className="">
                    <tr>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Name</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Cost</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Hours</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Rate</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Status</th>
                        <th scope="col" className="py-2 uppercase font-medium text-sm">Action</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300">
                    {props.tasks.map((task) => (
                        <Task key={task.id} task={task} />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="">
                        <td colSpan="6" className="py-2 font-medium text-sm text-right pr-4">Showing {props.tasks.length} tasks in total</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default Tasks
