import React from 'react'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { deleteMaintenance } from '../actions';

function Maintenance(props) {
    const token = useSelector(state => state.token)
    const history = useHistory();
    const dispatch = useDispatch()

    const formatDate = ((date)=> {
        if (!date){
            return ''
        }
        const d = new Date(date)
        const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
        const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)
        const year = d.getFullYear()

        return day + "/" + month + "/" + year
    })

    const edit = (maintenance) => {
        history.push(`/maintenances/${maintenance.id}`)
    }

    const handleDelete = (maintenance) => {
        if (window.confirm(`Are you sure you want to delete ${maintenance.description} from maintenances?`)) {
            fetch(`https://admin.lamtechsoftwareservices.com/api/maintenances/${maintenance.id}/`, {
                method: "DELETE",
                headers: {
                    "Authorization": "Token " + token 
                }
            })
            .then(dispatch(deleteMaintenance(maintenance)))
        }
    }

    return (
        <tr className="bg-green-100">
            <td className="p-2 w-2/6">{props.maintenance.description}</td>
            <td className="p-2 w-1/6">{props.maintenance.amount}</td>
            <td className="p-2 w-1/6">{formatDate(props.maintenance.fromDate)}</td>
            <td className="p-2 w-1/6">{formatDate(props.maintenance.endDate)}</td>
            <td className="p-2">
                <div className="flex justify-center gap-2">
                    <button className="bg-yellow-500 hover:bg-yellow-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => edit(props.maintenance)}>
                        <PencilAltIcon className=" w-5 h-5" />
                    </button>
                    <button className="bg-red-500 hover:bg-red-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => handleDelete(props.maintenance)}>
                        <TrashIcon className=" w-5 h-5" />
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default Maintenance
