import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ArrowLeftIcon, PencilAltIcon } from '@heroicons/react/outline'
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB/index.js';
import 'react-datepicker/dist/react-datepicker.css'

function EditProject() {
    const id = parseInt(useParams().id)
    const token = useSelector(state => state.token)
    const history = useHistory()
    registerLocale('en-GB', enGB)

    const [visible, setVisible] = useState(false)
    const [name, setName] = useState("")
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const handleDismiss = () => {
        setVisible(false)
    }

    const unFormatDate = ((date) => {
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
        const year = date.getFullYear()
        return year + "-" + month + "-" + day
    })

    const handleEdit = (e) => {
        e.preventDefault()

        const data = {
            name,
            startDate: unFormatDate(startDate),
        }

        if (endDate) {
            data["endDate"] = unFormatDate(endDate)
        }

        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/`, {
            method: "PATCH",
            headers: {
                "Authorization": "Token " + token,
                "Content-Type": "application/json" 
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then((data) => {
            setVisible(true)
        })
    }

    useEffect(() => {
        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/`, {
            headers: {
                "Authorization": "Token " + token 
            }
        })
        .then(res => res.json())
        .then((data) => {
            setName(data.name)
            setStartDate(new Date(data.startDate))
            setEndDate(new Date(data.endDate))
        })
    }, [])

    return (
        <div>
            <button className="text-white bg-gray-800 hover:bg-gray-900 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.goBack()}>
                <ArrowLeftIcon className=" w-5 h-5 mr-2" />
                <span>Back</span>
            </button>

            { visible && 
                <div className="mx-auto max-w-xl transition ease-in delay-1000 mt-4 bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">Project updated!</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg onClick={() => handleDismiss()}className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                    </span>
                </div>
            }

            <form className="w-full max-w-xl mx-auto shadow gap-2 p-4 mt-4 bg-gray-200 rounded-lg" onSubmit={handleEdit}>
                <h1 className="text-3xl mb-4">Edit Project</h1>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Title</label>
                        <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholder="Title" type="text" name="name" value={name} required onChange={(e) => setName(e.target.value)}></input>
                    </div>
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Start Date</label>
                        <DatePicker selected={startDate} className="w-full shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholderText="Start Date" onChange={date => setStartDate(date)} required dateFormat="P" locale="en-GB"/>
                    </div>
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">End Date</label>
                        <DatePicker selected={endDate} className="w-full shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" placeholderText="End Date" onChange={date => setEndDate(date)} dateFormat="P" locale="en-GB"/>
                    </div>

                    <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center">
                        <PencilAltIcon className="w-5 h-5 mr-2" />
                        <span>Update</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditProject
