import {useState, useEffect} from 'react';
import ProjectItem from './ProjectItem';
import { PlusCircleIcon } from "@heroicons/react/outline"
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProjectsList() {
    const token = useSelector(state => state.token)
    const history = useHistory()
    const [projects, setProjects] = useState([])
    const [filteredProjects, setFilteredProjects] = useState([])
    const [name, setName] = useState("")

    useEffect(() => {
        fetch("https://admin.lamtechsoftwareservices.com/api/projects/", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + token 
            }
        })
        .then(res => res.json())
        .then((res) => {
            setProjects(res)
            setFilteredProjects(res)
        })
    }, [])

    useEffect(() => {
        if (name === ""){
            setFilteredProjects(projects)
        }
        else{
            const filtered = projects.filter((project) => {
                return (project.name.toLowerCase().includes(name.toLowerCase()))
            })
            setFilteredProjects(filtered)
        }       
    }, [name, projects])

    const handleChange = (e) => {
        setName(e.target.value)
    }

    return (
        <div>
            <div className="flex justify-between">
                <h1 className="text-3xl mb-2">Projects</h1>
                <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.push('/projects/add')}>
                    <PlusCircleIcon className=" w-5 h-5 mr-2" />
                    <span>Add</span>
                </button>
            </div>

            <h1 className="text-xl mb-2">Search</h1>
            <input className="w-full sm:w-auto shadow rounded border py-2 px-3 focus:outline-none focus:shadow-outline" type="text" value={name} placeholder="Name" onChange={handleChange}></input>
        
            <div className="grid grid-cols-1 gap-4 mt-4">
                {filteredProjects.length > 0 
                    ?
                        filteredProjects.map((project) => (
                            <ProjectItem key={project.id} project={project} />
                        )) 
                    : 
                        <h1 className="text-xl mt-2">No projects</h1>
                }
            </div>
        </div>
    )
}

export default ProjectsList
