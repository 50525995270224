const PaymentsReducer = (state=[], action) => {
    switch(action.type){
        case "FETCH_PAYMENTS":
            return action.payload
        case "ADD_PAYMENT":
            return [
                ...state,
                action.payload
            ]
        case "DELETE_PAYMENT":
            return state.filter(payment => payment.id !== action.payload.id)
        default:
            return state
    }
}

export default PaymentsReducer;