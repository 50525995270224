import { CheckIcon, PencilAltIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { deleteTask, toggleTask } from '../actions'

function Task(props) {
    const token = useSelector(state => state.token)
    const history = useHistory()
    const dispatch = useDispatch()

    const toggle = (task) => {
        fetch(`https://admin.lamtechsoftwareservices.com/api/tasks/${task.id}/`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Token " + token 
            },
            body: JSON.stringify({
                completed: !task.completed,
            })
        })
        .then(dispatch(toggleTask(task)))
    }

    const edit = (task) => {
        history.push(`/tasks/${task.id}`)
    }

    const handleDelete = (task) => {
        if (window.confirm(`Are you sure you want to delete ${task.name} from tasks?`)) {
            fetch(`https://admin.lamtechsoftwareservices.com/api/tasks/${task.id}/`, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Token " + token 
                }
            })
            .then(dispatch(deleteTask(task)))
        }
    }

    return (
        <tr className={props.task.completed ? "bg-green-100" : "bg-red-100"}>
            <td className="p-2 w-1/6">{props.task.name}</td>
            <td className="p-2 w-1/6">{props.task.cost}</td>
            <td className="p-2 w-1/6">{props.task.hours}</td>
            <td className="p-2 w-1/6">{(props.task.cost / props.task.hours || 0).toFixed(2)}</td>
            <td className="p-2 w-1/6">
                {props.task.completed 
                    ?
                        <span className="text-sm inline-flex items-center justify-center px-2 py-1 font-bold leading-none text-white bg-green-500 rounded-full">Completed</span>
                    :
                        <span className="text-sm inline-flex items-center justify-center px-2 py-1 font-bold leading-none text-white bg-red-500 rounded-full">Pending</span>
                }
            </td>
            <td className="p-2 flex justify-center gap-2">
                <div className="flex justify-center gap-2">
                    { props.task.completed 
                        ?
                            <button className="bg-red-500 hover:bg-red-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => toggle(props.task)}>
                                <XIcon className=" w-5 h-5" />
                            </button>
                        :
                            <button className="bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => toggle(props.task)}>
                                <CheckIcon className=" w-5 h-5" />
                            </button>
                    }
                    <button className="bg-yellow-500 hover:bg-yellow-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => edit(props.task)}>
                        <PencilAltIcon className=" w-5 h-5" />
                    </button>
                    <button className="bg-red-500 hover:bg-red-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => handleDelete(props.task)}>
                        <TrashIcon className=" w-5 h-5" />
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default Task
