const MaintenancesReducer = (state=[], action) => {
    switch(action.type){
        case "FETCH_MAINTENANCES":
            return action.payload
        case "ADD_MAINTENANCE":
            return [
                ...state,
                action.payload
            ]
        case "DELETE_MAINTENANCE":
            return state.filter(maintenance => maintenance.id !== action.payload.id)
        default:
            return state
    }
}

export default MaintenancesReducer;