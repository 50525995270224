import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ArrowLeftIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline'
import Clients from './Clients'
import Tasks from './Tasks'
import Payments from './Payments'
import { fetchClients, fetchMaintenances, fetchPayments, fetchTasks } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import Summary from './Summary'
import { PencilAltIcon } from '@heroicons/react/solid'
import Maintenances from './Maintenances'

function Project() {
    const token = useSelector(state => state.token)
    const id = useParams().id
    const history = useHistory()
    const dispatch = useDispatch()

    const clients = useSelector(state => state.clients)
    const tasks = useSelector(state => state.tasks)
    const payments = useSelector(state => state.payments)
    const maintenances = useSelector(state => state.maintenances)

    const handleEdit = () => {
        history.push(`/projects/${id}/edit`)
    }

    const handleDelete = () => {
        if (window.confirm("Are you sure you want to delete this project?")) {
            fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/`, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Token " + token 
                }
            })
            .then(history.push("/projects"))
        }
    }

    useEffect(() => {
        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/clients/`, {
            headers: {
                "Authorization": "Token " + token 
            }
        })
        .then(res => res.json())
        .then((data) => {
            dispatch(fetchClients(data))
        })

        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/tasks/`, {
            headers: {
                "Authorization": "Token " + token 
            }
        })
        .then(res => res.json())
        .then((data) => {
            dispatch(fetchTasks(data))
        })

        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/payments/`, {
            headers: {
                "Authorization": "Token " + token 
            }
        })
        .then(res => res.json())
        .then((data) => {
            dispatch(fetchPayments(data))
        })

        fetch(`https://admin.lamtechsoftwareservices.com/api/projects/${id}/maintenances/`, {
            headers: {
                "Authorization": "Token " + token 
            }
        })
        .then(res => res.json())
        .then((data) => {
            dispatch(fetchMaintenances(data))
        })
    }, [])

    return (
        <div>
            <div className="flex justify-between">
                <div>
                    <button className="text-white bg-gray-800 hover:bg-gray-900 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.goBack()}>
                        <ArrowLeftIcon className=" w-5 h-5 mr-2" />
                        <span>Back</span>
                    </button>
                </div>
                <div className="flex gap-2">
                    <button className="text-white bg-yellow-500 hover:bg-yellow-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => handleEdit()}>
                        <PencilAltIcon className=" w-5 h-5 mr-2" />
                        <span>Edit</span>
                    </button>
                    <button className="text-white bg-red-500 hover:bg-red-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => handleDelete()}>
                        <TrashIcon className=" w-5 h-5 mr-2" />
                        <span>Delete</span>
                    </button>
                </div>
            </div>

            <div className="my-4 flex justify-between">
                <h1 className="text-3xl">Clients</h1>
                <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.push(`/projects/${id}/clients`)}>
                    <PlusIcon className=" w-5 h-5 mr-2" />
                    <span>Add</span>
                </button>
            </div>

            { clients.length > 0 
            ?
                <Clients clients={clients} />
            : 
                <h1 className="text-xl mt-2">No clients</h1>
            }

            <hr className="mt-8" />

            <div className="mt-8 mb-4 flex justify-between">
                <h1 className="text-3xl">Tasks</h1>
                <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.push(`/projects/${id}/tasks`)}>
                    <PlusIcon className=" w-5 h-5 mr-2" />
                    <span>Add</span>
                </button>
            </div>
            { tasks.length > 0 
            ?
                <Tasks tasks={tasks} />
            : 
                <h1 className="text-xl mt-2">No tasks</h1>
            }

            <hr className="mt-8" />

            <div className="mt-8 mb-4 flex justify-between">
                <h1 className="text-3xl">Payments</h1>
                <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.push(`/projects/${id}/payments`)}>
                    <PlusIcon className=" w-5 h-5 mr-2" />
                    <span>Add</span>
                </button>
            </div>
            { payments.length > 0 
            ?
                <Payments payments={payments} />
            : 
                <h1 className="text-xl mt-2">No payments</h1>
            }

            <hr className="mt-8" />

            <div className="mt-8 mb-4 flex justify-between">
                <h1 className="text-3xl">Maintenances</h1>
                <button className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded inline-flex items-center justify-center" onClick={() => history.push(`/projects/${id}/maintenances`)}>
                    <PlusIcon className=" w-5 h-5 mr-2" />
                    <span>Add</span>
                </button>
            </div>
            { maintenances.length > 0 
            ?
                <Maintenances maintenances={maintenances} />
            : 
                <h1 className="text-xl mt-2">No maintenances</h1>
            }

            <hr className="mt-8" />
            
            <h1 className="text-3xl mt-8 mb-4">Summary</h1>
            <Summary tasks={tasks} payments={payments} maintenances={maintenances} /> 
        </div>
    )
}

export default Project
